import React from 'react';

const CaseStudyContentMigration = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0796 35.54H7.5C7.17264 35.5427 6.84763 35.4806 6.54443 35.3572C6.24123 35.2337 5.96581 35.0514 5.7334 34.8208C5.50099 34.5902 5.31631 34.3161 5.19043 34.0139C5.06455 33.7117 4.99999 33.3874 5 33.0601V7.5C5 6.83696 5.26309 6.20126 5.73193 5.73242C6.20077 5.26358 6.83696 5 7.5 5H21.0796C21.7426 5 22.3788 5.26358 22.8477 5.73242C23.3165 6.20126 23.5796 6.83696 23.5796 7.5V33.0601C23.5796 33.3874 23.515 33.7117 23.3892 34.0139C23.2633 34.3161 23.0786 34.5902 22.8462 34.8208C22.6138 35.0514 22.3384 35.2337 22.0352 35.3572C21.732 35.4806 21.4069 35.5427 21.0796 35.54ZM7.5 6C7.10218 6 6.72027 6.15815 6.43896 6.43945C6.15766 6.72076 6 7.10218 6 7.5V33.0601C5.99998 33.2561 6.03863 33.4502 6.11426 33.6311C6.18988 33.812 6.30087 33.9761 6.44043 34.1138C6.57999 34.2515 6.74537 34.3602 6.92725 34.4333C7.10912 34.5065 7.30397 34.5427 7.5 34.54H21.0796C21.474 34.5401 21.8526 34.3849 22.1333 34.1079C22.414 33.8309 22.5743 33.4544 22.5796 33.0601V7.5C22.5744 7.10379 22.415 6.72526 22.1348 6.44507C21.8546 6.16488 21.4758 6.00518 21.0796 6H7.5Z"
      fill="#1A1A1A"
    />
    <path
      d="M23.0796 8.69531H5.5C5.36739 8.69531 5.24025 8.6426 5.14648 8.54883C5.05272 8.45506 5 8.32792 5 8.19531C5 8.0627 5.05272 7.93532 5.14648 7.84155C5.24025 7.74778 5.36739 7.69531 5.5 7.69531H23.0796C23.2122 7.69531 23.3398 7.74778 23.4336 7.84155C23.5274 7.93532 23.5796 8.0627 23.5796 8.19531C23.5796 8.32792 23.5274 8.45506 23.4336 8.54883C23.3398 8.6426 23.2122 8.69531 23.0796 8.69531Z"
      fill="#1A1A1A"
    />
    <path
      d="M23.0796 31.6797H5.5C5.36739 31.6797 5.24025 31.627 5.14648 31.5332C5.05272 31.4394 5 31.3123 5 31.1797C5 31.0471 5.05272 30.9199 5.14648 30.8262C5.24025 30.7324 5.36739 30.6797 5.5 30.6797H23.0796C23.2122 30.6797 23.3398 30.7324 23.4336 30.8262C23.5274 30.9199 23.5796 31.0471 23.5796 31.1797C23.5796 31.3123 23.5274 31.4394 23.4336 31.5332C23.3398 31.627 23.2122 31.6797 23.0796 31.6797Z"
      fill="#1A1A1A"
    />
    <path
      d="M15.1997 33.6406H13.3799C13.2473 33.6406 13.1201 33.5879 13.0264 33.4941C12.9326 33.4004 12.8799 33.2732 12.8799 33.1406C12.8799 33.008 12.9326 32.8809 13.0264 32.7871C13.1201 32.6933 13.2473 32.6406 13.3799 32.6406H15.1997C15.3323 32.6406 15.4595 32.6933 15.5532 32.7871C15.647 32.8809 15.6997 33.008 15.6997 33.1406C15.6997 33.2732 15.647 33.4004 15.5532 33.4941C15.4595 33.5879 15.3323 33.6406 15.1997 33.6406Z"
      fill="#1A1A1A"
    />
    <path
      d="M9.00007 27.6771C8.61376 27.6573 8.24141 27.5259 7.92829 27.2987C7.61517 27.0716 7.37472 26.7584 7.23591 26.3974C7.0971 26.0363 7.06574 25.6427 7.14607 25.2643C7.2264 24.8859 7.41454 24.539 7.68806 24.2655C7.96158 23.992 8.30869 23.8036 8.68708 23.7233C9.06547 23.643 9.45933 23.6741 9.82038 23.8129C10.1814 23.9517 10.4941 24.1922 10.7213 24.5053C10.9484 24.8184 11.0798 25.1907 11.0997 25.577C11.114 25.8566 11.0698 26.1361 10.9693 26.3974C10.8689 26.6587 10.7146 26.896 10.5167 27.0939C10.3187 27.2918 10.0817 27.4461 9.82038 27.5465C9.55909 27.647 9.27964 27.6915 9.00007 27.6771ZM9.00007 24.4772C8.78251 24.4772 8.56964 24.5416 8.38874 24.6625C8.20785 24.7834 8.06673 24.9552 7.98347 25.1562C7.90021 25.3571 7.87853 25.5783 7.92097 25.7916C7.96341 26.005 8.0684 26.201 8.22224 26.3549C8.37608 26.5087 8.57185 26.6135 8.78523 26.6559C8.99861 26.6983 9.21997 26.6767 9.42097 26.5934C9.62197 26.5101 9.79375 26.369 9.91462 26.1881C10.0355 26.0072 10.0997 25.7946 10.0997 25.577C10.0997 25.4326 10.0715 25.2896 10.0162 25.1562C9.9609 25.0227 9.88005 24.9014 9.7779 24.7992C9.67576 24.6971 9.55443 24.616 9.42097 24.5607C9.28751 24.5054 9.14453 24.4772 9.00007 24.4772Z"
      fill="#1A1A1A"
    />
    <path
      d="M17.0797 15.8569C16.689 15.8373 16.3126 15.7037 15.9972 15.4724C15.6817 15.2411 15.4409 14.9224 15.3048 14.5556C15.1686 14.1889 15.1429 13.79 15.231 13.4089C15.3191 13.0277 15.5171 12.6809 15.8004 12.4111C16.0837 12.1413 16.4398 11.9605 16.8248 11.8911C17.2098 11.8217 17.6067 11.8668 17.9664 12.0207C18.3261 12.1746 18.6331 12.4304 18.8487 12.7568C19.0644 13.0832 19.1793 13.4657 19.1798 13.8569C19.1801 14.1282 19.1253 14.3968 19.0186 14.6462C18.9119 14.8956 18.7556 15.1205 18.5592 15.3076C18.3627 15.4947 18.1304 15.6399 17.8761 15.7343C17.6217 15.8288 17.3506 15.8705 17.0797 15.8569ZM17.0797 12.6569C16.9352 12.6569 16.7922 12.6854 16.6588 12.7407C16.5253 12.796 16.404 12.8768 16.3018 12.979C16.1997 13.0811 16.1188 13.2024 16.0636 13.3359C16.0083 13.4694 15.9801 13.6123 15.9801 13.7568C15.9801 14.0485 16.0956 14.3283 16.3018 14.5346C16.5081 14.7409 16.7879 14.8569 17.0797 14.8569C17.3714 14.8569 17.6512 14.7409 17.8575 14.5346C18.0638 14.3283 18.1798 14.0485 18.1798 13.7568C18.1745 13.4685 18.0564 13.1939 17.8507 12.9919C17.6449 12.7899 17.368 12.6769 17.0797 12.677V12.6569Z"
      fill="#1A1A1A"
    />
    <path
      d="M27.2603 24.4741C26.8696 24.4546 26.4933 24.3207 26.1778 24.0894C25.8623 23.8581 25.6216 23.5394 25.4854 23.1726C25.3493 22.8059 25.3236 22.4073 25.4117 22.0261C25.4998 21.645 25.6978 21.2981 25.981 21.0283C26.2643 20.7585 26.6205 20.5777 27.0055 20.5083C27.3904 20.4389 27.7874 20.4838 28.1471 20.6377C28.5067 20.7916 28.8132 21.0477 29.0289 21.374C29.2446 21.7004 29.3599 22.0829 29.3604 22.4741C29.3608 22.7454 29.306 23.0138 29.1993 23.2632C29.0926 23.5126 28.9363 23.7377 28.7398 23.9248C28.5434 24.1119 28.311 24.2572 28.0567 24.3516C27.8024 24.446 27.5313 24.4877 27.2603 24.4741ZM27.2603 21.2739C26.9686 21.2739 26.6888 21.3899 26.4825 21.5962C26.2762 21.8025 26.1602 22.0823 26.1602 22.374C26.1602 22.6658 26.2762 22.9456 26.4825 23.1519C26.6888 23.3582 26.9686 23.4741 27.2603 23.4741C27.5521 23.4741 27.8319 23.3582 28.0382 23.1519C28.2445 22.9456 28.3604 22.6658 28.3604 22.374C28.3604 22.0823 28.2445 21.8025 28.0382 21.5962C27.8319 21.3899 27.5521 21.2739 27.2603 21.2739Z"
      fill="#1A1A1A"
    />
    <path
      d="M25.7402 22.3542C25.6044 22.3482 25.4759 22.2911 25.3804 22.1943L18.2202 14.6343C18.1286 14.5408 18.0771 14.4151 18.0771 14.2842C18.0771 14.1533 18.1286 14.0278 18.2202 13.9343C18.2669 13.8859 18.3229 13.8473 18.3848 13.821C18.4466 13.7948 18.5129 13.7812 18.5801 13.7812C18.6473 13.7812 18.714 13.7948 18.7759 13.821C18.8377 13.8473 18.8938 13.8859 18.9404 13.9343L26.1001 21.4941C26.1917 21.5876 26.2432 21.7134 26.2432 21.8442C26.2432 21.9751 26.1917 22.1009 26.1001 22.1943C26.0548 22.245 25.9996 22.2854 25.9375 22.313C25.8754 22.3406 25.8081 22.3546 25.7402 22.3542Z"
      fill="#1A1A1A"
    />
    <path
      d="M10.2999 25.1604C10.2209 25.1784 10.1386 25.1784 10.0596 25.1604C9.9461 25.0936 9.86383 24.9847 9.83012 24.8574C9.79642 24.7301 9.81432 24.5947 9.87993 24.4805L15.3399 14.4805C15.3713 14.4226 15.4137 14.3712 15.4649 14.3298C15.5161 14.2884 15.575 14.2576 15.6382 14.239C15.7014 14.2204 15.7676 14.2146 15.8331 14.2217C15.8985 14.2288 15.9623 14.2486 16.0201 14.2803C16.1332 14.3473 16.2164 14.4549 16.2535 14.581C16.2906 14.7072 16.2786 14.8428 16.2198 14.9604L10.7398 24.9604C10.6893 25.0282 10.6224 25.082 10.5455 25.1169C10.4685 25.1519 10.3841 25.167 10.2999 25.1604Z"
      fill="#1A1A1A"
    />
    <path
      d="M28.46 21.8589C28.3425 21.8577 28.2289 21.8151 28.1396 21.7388C28.048 21.6453 27.9971 21.5198 27.9971 21.3889C27.9971 21.258 28.048 21.1323 28.1396 21.0388L34.1396 13.479C34.2331 13.3874 34.3589 13.3359 34.4897 13.3359C34.6206 13.3359 34.7464 13.3874 34.8398 13.479C34.8877 13.5239 34.9256 13.578 34.9517 13.6382C34.9777 13.6984 34.9912 13.7633 34.9912 13.8289C34.9912 13.8945 34.9777 13.9593 34.9517 14.0195C34.9256 14.0797 34.8877 14.1341 34.8398 14.179L28.8398 21.7588C28.7865 21.8003 28.7255 21.8307 28.6602 21.8479C28.5948 21.8651 28.5268 21.8687 28.46 21.8589Z"
      fill="#1A1A1A"
    />
    <path
      d="M34.9397 17.161C34.8222 17.1619 34.7084 17.1195 34.6199 17.0421C34.5314 16.9647 34.4744 16.8575 34.4598 16.7408L34.0994 14.361L31.5198 14.8009C31.4541 14.8123 31.3866 14.8103 31.3216 14.7953C31.2566 14.7802 31.195 14.7524 31.1409 14.7133C31.0869 14.6741 31.0412 14.6246 31.0066 14.5675C30.972 14.5104 30.9494 14.447 30.9397 14.381C30.9214 14.2517 30.9521 14.1203 31.0262 14.0128C31.1003 13.9054 31.2123 13.8297 31.3396 13.8009L34.4397 13.2809C34.5631 13.2397 34.6963 13.2397 34.8196 13.2809C34.8761 13.3192 34.9232 13.3695 34.9578 13.4283C34.9924 13.4871 35.0138 13.553 35.0198 13.621L35.4397 16.5209C35.4569 16.6506 35.4226 16.7821 35.344 16.8868C35.2655 16.9915 35.1492 17.0611 35.0198 17.0809L34.9397 17.161Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CaseStudyContentMigration;
